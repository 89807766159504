// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

// Import Custom Colors
@import 'colors';

form {
  .col-form-label {
    padding-bottom: 5px !important;
  }

  .alert {
    font-size: 14px !important;
    padding: 4px 11px !important;
  }
}

app-notifications .list-group-item-empty {
  justify-content: flex-start !important;
}

app-notifications .display-6 {
  font-size: 1.5rem;
}

app-notifications {
  --loading-spinner-size: 30px;

  .rpanel-header {
    margin-right: 0.5rem !important;
  }
}
